<template>
    <echarts-com v-loading="loading" id="userPreference-adClickStatistics" :options="options"/>
</template>

<script>

import {formatRatio} from "@/utils/util"

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      options: {},
      loading: false
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions(params={}) {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/pay/amount/preference", params);
        if (res.code === 0) {
          if(res.data){
            let data = res.data.infoList.map(item=>{
              return {
                value: item.payAmount,
                percentage: formatRatio(item.percentage) + "%",
                name: item.platformType
              }
            })

            this.$set(this, "options", {
              tooltip: {
                trigger: 'item'
              },
              legend: {
                type: 'scroll',
                icon:"circle",
                orient: 'vertical',
                right: 0,
                top: 'center',
                itemWidth:10,
                itemHeight:10,
                formatter(labelName){
                  let item = data.find(item=>item.name===labelName)
                  return `{name|${item.name}}{dividingLine||}{percentage|${item.percentage}}`
                },
              },
              textStyle:{
                rich: {
                  name:{
                    width:80,
                    color:"white"
                  },
                  dividingLine: {
                    color:"#eee",
                    width: 10,
                  },
                  percentage:{
                    color:"white",
                    width: 55,
                  }
                }
              },
              series: [
                {
                  type: 'pie',
                  radius: ['67%', '90%'],
                  left: 0,
                  right: '50%',
                  top: 0,
                  bottom: 0,
                  avoidLabelOverlap: false,
                  itemStyle:{
                    borderWidth:100
                  },
                  label: {
                    show: true,
                    position: 'center',
                    color: '#4c4a4a',
                    formatter:()=>{
                      return `{total|${res.data.totalAmount}}{unit|次}`
                    },
                    rich: {
                      unit: {
                        fontSize: 10,
                        color: 'white'
                      },
                      total: {
                        fontSize: 24,
                        color: 'white'
                      }
                    }
                  },
                  data
                }
              ]
            })
          }else{
            this.$set(this, "options",{})
            this.list=[]
          }
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("支付偏好分析获取失败");
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
#userPreference-adClickStatistics {
  display: flex;
  #userPreference-adClickStatistics-chart{
    width: 50%;
    height: 100%;
  }
}
</style>
